//import logo from './logo.svg';
/* import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header>
    </div>
  );
}

export default App;
 */

import './App.css';
import React, { useEffect } from 'react';
import { ImageBackground , ScrollView , StyleSheet, CheckBox, Text, View, Dimensions, Image, FlatList, TouchableWithoutFeedback, TextInput, Alert, Platform} from 'react-native';
import { Modal, Linking } from 'react-native';
//import {io} from 'socket.io-client';
import { useMediaQuery } from 'usehooks-ts';


import bitcoindigits_svg from './img/bitcoindigits.svg';
import lobtcdgtsgo_svg from './img/btcdgts-parallax.svg';
import discord_icon_svg from './img/discord-icon.svg';
import twitter_icon_svg from './img/twitter-icon.svg';
import meden_icon from './img/Magic-Eden-trans.png';
import okx_icon  from './img/okx_trans.png';


function Link(props) {
  return <Text {...props} accessibilityRole="link" style={StyleSheet.compose(styles.link, props.style)} />;
}



const apiurl = 'https://api.12percent.hu/apiqcats/';

let ScreenHeight = Dimensions.get('window').height;
let ScreenWidth = Dimensions.get('window').width;

//render items

function MouseEnter(event) {
  
  //event.target.style.borderWidth = 2;
  //event.target.style.borderColor = '#F1F1F1';
  //event.target.style.background = '#F1F1F1';
  //event.target.firstChild.style.background = '#F1F1F1';
  //event.target.style.color = '#252525';
  //event.target.firstChild.style.color="#252525";
  
}
function onMouseLeave(event){

  //event.target.style.borderWidth = 0;
  //event.target.style.borderColor = '#F1F1F1';
  //event.target.style.background="#000";
  //event.target.firstChild.style.background="#000";
  //event.target.style.color = '#FFFFFF';
  //event.target.firstChild.style.color="#FFFFFF";
  
}
/* 
function MouseEnterBtn(event) {
  event.target.style.background = '#000';
  event.target.firstChild.style.background = '#000';
  event.target.style.color = '#696969';
  event.target.firstChild.style.color="#696969";
  event.target.style.borderColor = '#696969';
}
function onMouseLeaveBtn(event){
  event.target.style.background = "#000";
  event.target.firstChild.style.background = "#000";
  event.target.style.color = '#FFF';
  event.target.firstChild.style.color="#FFF";
  event.target.style.borderColor = '#FFF';

}
  */


function App() {

  const isDesktopLandscape = useMediaQuery('(min-width: 768px)');


  const [loading, setLoading] = React.useState(true);
  const [collectionType, setCollectionType] = React.useState(3);
  const [collectionIsPrime, setCollectionIsPrime] = React.useState(0);
  const [collectionIsFibo, setCollectionIsFibo] = React.useState(0);
  const [collectionIsPoker, setCollectionIsPoker] = React.useState(0);
  const [collectionIsPalindrome, setCollectionIsPalindrome] = React.useState(0);
  const [numbers, setNumbers] = React.useState([]);
  const [numColumns, setNumColumns] = React.useState(1);
  const [listKey, setListKey] = React.useState('one-column');
  
  const [offset, setOffset] = React.useState(1);
  const [hasMoreItemToLoad, setHasMoreItemToLoad] = React.useState(true);
  
  const [searchDataView, setSearchDataView] = React.useState('');
  const [searchAdmin, setSearchAdmin] = React.useState('');
  

  const [filterType, setFilterType] = React.useState('All');
  const [sortType, setSortType] = React.useState('Asc');
  const [subType, setSubType] = React.useState('All');
  const [filteredItem, setFilteredItem] = React.useState('');
  
  const [yHeld, setYHeld] = React.useState(false);
  const [xHeld, setXHeld] = React.useState(false);
  const [controlHeld, setControlHeld] = React.useState(false);
   
  const [approveDropdownVisible, setApproveDropdownVisible] = React.useState(false);
  const [ordinalDropdownVisible, setOrdinalDropdownVisible] = React.useState(false);

  const [ordinalSelectOptions, setOrdinalSelectOptions] = React.useState([]);
  const [ordinalSelectType, setOrdinalSelectType] = React.useState(-1);
  const [searchOrdinalSelect, setSearchOrdinalSelect] = React.useState('');
  
  const [openAdminPass, setOpenAdminPass] = React.useState(false);
  const [adminPass, setAdminPass] = React.useState('');
  const [adminPassIamHuman, setAdminPassIamHuman] = React.useState(false);


  const [confirmRegistrationSaveVisible, setConfirmRegistrationSaveVisible] = React.useState(false);
  const [registrationIamHuman, setRegistrationIamHuman] = React.useState(false);

  const [confirmAdminSaveVisible, setConfirmAdminSaveVisible] = React.useState(false);
  const [adminSaveIamHuman, setAdminSaveIamHuman] = React.useState(false);
 
  const [confirmAdminDeleteVisible, setConfirmAdminDeleteVisible] = React.useState(false);
  const [adminDeleteIamHuman, setAdminDeleteIamHuman] = React.useState(false);

  const [adminReadOrdsVisible, setAdminReadOrdsVisible] = React.useState(false);
  



  const [selectedItemForEdit, setSelectedItemForEdit] = React.useState('');
  const [selectedItemForDelete, setSelectedItemForDelete] = React.useState('');
  
  const [clickLocationX, setClickLocationX] = React.useState(0);
  const [clickLocationY, setClickLocationY] = React.useState(0);

  const [selectedItemForDataView, setSelectedItemForDataView] = React.useState('');
  const [selectedItemForRegistration, setSelectedItemForRegistration] = React.useState('');
  
  const [itemData, setItemData] = React.useState([]);
  
  const [isAboutVisible, setAboutVisible] = React.useState(false);
  const [isFaqVisible, setFaqVisible] = React.useState(false);

  const [isAdminVisible, setAdminVisible] = React.useState(false);
  const [itemAdminData, setItemAdminData] = React.useState([]);
  const [forceReloadAdmin, setForceReloadAdmin] = React.useState(false);
    
  const [itemOrdinalData, setItemOrdinalData] = React.useState([]);
  const [itemOrdinalDataButton, setItemOrdinalDataButton] = React.useState('Read Digits');
  const [ordinalDataFrom, setOrdinalDataFrom] = React.useState('101');
  const [ordinalDataTo, setOrdinalDataTo] = React.useState('801');
  const [searchOrdinalData, setSearchOrdinalData] = React.useState('');
  const [searchOrdinalDataOnlyDigits, setSearchOrdinalDataOnlyDigits] = React.useState(true);

  const [isRegistrationPanelVisible, setRegistrationPanelVisible] = React.useState(false);
  const [isDataViewPanelVisible, setDataViewPanelVisible] = React.useState(false);
  
  //save
  const [saveOrdId, setSaveOrdId] = React.useState('');
  const [saveAddress, setSaveAddress] = React.useState('');
  const [saveTransId, setSaveTransId] = React.useState('');
  const [saveTwitterId, setSaveTwitterId] = React.useState('');
  const [saveDiscordId, setSaveDiscordId] = React.useState('');
  const [savetimestamp, setSavetimestamp] = React.useState('');

  const [btcData, setBtcData] = React.useState(null);

  const ordDataRef = React.useRef([]);


  /* --------------------------------------------------------------------------- */


  const getBitcoinData = async () => {

    try {
      

      var url = apiurl + 'getBitcoinData';
      //console.log('Retrieved from database...', url);
      fetch(url, {
          
          method: 'GET',
          headers: {
              'Accept': 'application/json'
          }
      })
          .then((response) => response.json())
          .then((json) => {
            setBtcData(json.data);
          })
          .catch((error) => {
            ////console.log(error);
          })
    } catch (e) {
      ////console.log(e);
    }


  };


  useEffect(() => {
   
    // Call getData when the component mounts
    getBitcoinData();

    // Set up the interval to call getData at a regular interval (e.g., every 5 seconds)
    const intervalId = setInterval(getBitcoinData, 50000); // 5000 ms = 5 seconds

    // Cleanup function to clear the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, []); 


  const renderItemStyle = (item) => {
      if (isDesktopLandscape) {
            return (item.CIV > 0 ? styles.itemverified : (item.CIR > 0 ?  styles.itemregistered : styles.emptyitem));
      } else {
            return (item.CIV > 0 ? styles.itemverified_small : (item.CIR > 0 ?  styles.itemregistered_small : styles.emptyitem_small));
      }
  }
  



  const renderItemLeaderboard = ({ item, index }) => (
    <View style={stylesLeader.item}>
       <TouchableWithoutFeedback onPress={() => {
                                    
          if (item.CIV > 0) {
            setSelectedItemForDataView(item);
          }
                                
      }}>
        <View style={{cursor:'pointer', width:'40%', height: 80,justifyContent: 'center', alignItems: 'flex-start'}}>
            <Image source={(item.type == 'qcat' ? require('./img/imagesQCats/' + item.image) : require('./img/imagesQRats/' + item.image))} style={{height:70, width:70}} ></Image>
        </View>
      </TouchableWithoutFeedback>
      <View style={{cursor:'pointer', width:'20%', height: 80,justifyContent: 'center', alignItems: 'center'}}>
        <Text style={{ fontFamily:'ShareTechMono', fontSize: 24, color: (item.CIV > 0 ? 'white' : 'white') }}>{item.name}</Text>
      </View>
      <View style={{width:'40%', height: 80, flexDirection:'column', justifyContent: 'center', alignItems: 'center'}}>
          <View style={{width:'100%', height: 40 , justifyContent: 'flex-start', alignItems: 'flex-end'}}>
            <Text style={{ fontFamily:'ShareTechMono', fontSize: 14, color: (item.CIV > 0 ? 'white' : 'white') }}>{(item.type == 'qcat' ? ("drained:" + item.eat_nr_dr).trim() + " | " + ("killed:" + item.eat_nr_ki).trim() : (item.CIV > 0 ? 'drained' : 'alive')) }</Text>
          </View>
          <View style={{width:'100%', height: 40 , justifyContent: 'center', alignItems: 'flex-end'}}>
            <Text style={{ fontFamily:'ShareTechMono', fontSize: 14, color: (item.CIV > 0 ? 'white' : 'white') }}>{(item.type == 'qcat' ? ("Share of the next AirDrop: " + (item.eat_nr / item.eat_nr_tot * 100).toFixed(2)).trim() + "%" : (item.CIV > 0 ? 'drained' : 'alive')) }</Text>
          </View>
      </View>
    </View>
  );

  const renderItem = ({ item, index }) => {

    return <View onMouseEnter={MouseEnter} onMouseLeave={onMouseLeave} style={renderItemStyle(item)} key={index} >{
                <TouchableWithoutFeedback onPress={() => {
                                    
                  if (item.CIV > 0) {
                    setSelectedItemForDataView(item);
                  }
              
              }}>
                  <View style={{width:'100%', height:'100%',flexDirection:'column', justifyContent: 'center', alignItems: 'center'}}>
                      
                      <View style={{width:80, height: 80,justifyContent: 'center', alignItems: 'center'}}>
                            <Image source={(item.type == 'qcat' ? require('./img/imagesQCats/' + item.image) : require('./img/imagesQRats/' + item.image))} style={{height:70, width:70}} ></Image>
                      </View>
                      
                      <Text style={{ fontFamily:'ShareTechMono', fontSize: 16, color: (item.CIV > 0 ? '#0F0A22' : 'white') }}>{item.name}</Text>
                      
                      <Text style={{ fontFamily:'ShareTechMono', fontSize: 14, color: (item.CIV > 0 ? '#0F0A22' : 'white') }}>{(item.type == 'qcat' ? ("drained:" + item.eat_nr_dr).trim() + " " + ("killed:" + item.eat_nr_ki).trim() : (item.CIV > 0 ? (item.eat_nr_dr=="1" ? 'drained' : "killed") : 'alive')) }</Text>
                      
                      {/* <View style={{paddingTop: 5, width:'100%', height:24, flexDirection:'row',justifyContent: 'center', alignItems: 'center'}}>
                         <TouchableWithoutFeedback onPress={() => {
                          
                              setSelectedItemForRegistration(item);
                          
                          }}>
                          <View style={{width:'50%', height:26,justifyContent: 'center', alignItems: 'flex-start'}}>
                              <Image source={(item.CIV > 0 ? require('./img/register_black.png') : require('./img/register.png'))} style={{height:24, width:24}} ></Image>
                          </View>
                          </TouchableWithoutFeedback>
                          <TouchableWithoutFeedback onPress={() => {
                          
                              setSelectedItemForDataView(item);
                          
                          }}>
                          <View style={{width:'50%', height:26,justifyContent: 'center', alignItems: 'flex-end'}}>
                              <Image source={(item.CIV > 0 ? require('./img/view_black.png') : require('./img/view.png'))} style={{height:22, width:26}} ></Image>
                          </View>
                          </TouchableWithoutFeedback>
                      </View> */}
                 </View>                
             </TouchableWithoutFeedback>
        }</View>;
  };
  
  useEffect(() => {
    (async () => {

        loadCollections();

    })();

  }, [collectionType, filterType, sortType, filteredItem, subType, collectionIsFibo, collectionIsPoker, collectionIsPrime, collectionIsPalindrome]); 
  
  
  function loadCollections() {
      
      try{

          let collection = collectionType;
          let isfibo = 0;
          let isprime = 0;
          let ispoker = 0;
          let ispalindrome = 0;
          if (collectionIsPrime==1) {
           //   collection=100;
              isprime=1;
          }
          if (collectionIsFibo==1) {
           // collection=100;
            isfibo=1;
          }
          if (collectionIsPalindrome==1) {
            // collection=100;
             ispalindrome=1;
           }
          if (collectionIsPoker==1) {
            // collection=100;
             ispoker=1;
           }

          setLoading(true); 
          let limit = (isDesktopLandscape) ? 500 : 100;
          var url = apiurl + 'getCollection?collection=' + collection + "&filter=" + filterType + "&sort=" + sortType + "&selected=" + filteredItem + "&offset=" + offset + "&limit=" + limit;
          //console.log('Retrieved from database...', url);
          fetch(url, {
              
              method: 'GET',
              headers: {
                  'Accept': 'application/json'
              }
          })
              .then((response) => response.json())
              .then((json) => {
                ////console.log(json.sql);
                if (limit > Object.keys(json.data).length) {
                  setHasMoreItemToLoad(false);
                } else {
                  setHasMoreItemToLoad(true);
                }
                
                  //console.log(json.sql);
                  ////console.log(Object.keys(json.data).length);

                  setOffset(offset + 1);
                  //Increasing the offset for the next API call
                  
                  if (offset==1) {
                    setNumbers(json.data)
                  } else {
                    setNumbers([...numbers, ...json.data]);
                  }

                  setLoading(false);
                  ////console.log(numbers);

              })
              .catch((error) => {
                ////console.log(error);
              })
      } catch (e) {
        ////console.log(e);
      }
      setLoading(false);
    }

    

    const toggleAboutModal = () => {
      setAboutVisible(!isAboutVisible);
    };

    const toggleFaqModal = () => {
      setFaqVisible(!isFaqVisible);
    };

    //selected item for registration
    useEffect(() => {
      (async () => {
  
        if (selectedItemForRegistration=='') {
            setRegistrationPanelVisible(false);
        } else {
            setRegistrationPanelVisible(true);
            setDataViewPanelVisible(false);  
        }
          
      })();
  
    }, [selectedItemForRegistration]); 


    
//selected item get
    useEffect(() => {
      (async () => {
  
          loadItemData();
  
      })();
  
    }, [selectedItemForDataView]); 

      
    
    
    function loadItemData() {
        
        try{
      
            if (selectedItemForDataView=='') {
              return;
            }
            var url = apiurl + 'getItemData?itemID=' + selectedItemForDataView.id + "&type=" + collectionType;
           // //console.log('Retrieved item from database...', url);
            fetch(url, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json'
                }
            })
                .then((response) => response.json())
                .then((json) => {
                  
                    setItemData(json.data)
                    
                 //   cancelRegistrationPanel(false);
                    setDataViewPanelVisible(true);
                })
                .catch((error) => {
                  ////console.log(error);
                })
        } catch (e) {
          ////console.log(e);
        }
  
      }
  
            


  function updateRegCount(item_id, numVer, numReg) {
    
    if (filterType === 'Empty') { //tehat torolni kell mert
        if (numVer>0 || numReg>0) {
          setNumbers(numbers.filter((n) => item_id !== n.id));
        }
    } else {
      setNumbers(
        numbers.map((number, index) =>
            number.id === item_id
                ? { ...number, CIV: numVer, CIR: numReg }
                : { ...number }

        )
      )
    }

  }

 
    
    function renderAbout() {

      return (

        <Modal visible={isAboutVisible}
                  animationType="fade"
                  transparent={true}>
                  <View style={stylesFaq.centeredView}>
                    <View style={stylesFaq.modalView}>
                      <Text style={[stylesFaq.modalText,{fontWeight:"bold", fontSize: (isDesktopLandscape) ? 20 : 15 }]}>About Bitcoin Digits{"\n"}{"\n"}
                            The very first and earliest registered digits in Ordinals are forever inscribed on the Bitcoin blockchain.
                      </Text>
                      
                      <TouchableWithoutFeedback
                        style={[stylesFaq.button, stylesFaq.buttonClose]}
                        onPress={() => toggleAboutModal()}>
                        <View style={[stylesFaq.button, stylesFaq.buttonClose,{cursor:'pointer', width:150, height: (isDesktopLandscape) ? 60 : 30, alignItems:'center', justifyContent:'center', backgroundColor:'#0000', borderWidth:1.5, borderColor: "#fff"}]}>
                          <Text style={[stylesFaq.buttonTextStyle, {fontSize: (isDesktopLandscape) ? 20 : 15}]}>Close About</Text>
                        </View>
                      </TouchableWithoutFeedback>
                    </View>
                  </View>
                </Modal> 

      )

    }

    
    function renderFaq () {

      return (

        <Modal visible={isFaqVisible}
                  animationType="slide"
                  transparent={true}>
                  <View style={stylesFaq.centeredView}>
                    <View style={[stylesFaq.modalView,{backgroundColor: "#0F0A22", borderWidth:1, borderColor:"white"}]}>
                      <Text style={[stylesFaq.modalText,{fontWeight:"bold", color:"white", fontSize:  (isDesktopLandscape) ? 14 : 12}]}>GAME INSTRUCTIONS{"\n"}{"\n"}
                          - Step 1.{"\n"}{"\n"}
                            Within your wallet that houses the Quantum Cat(s), procure one or more Quantum Rat(s).{"\n"}{"\n"}
                          - Step 2.{"\n"}{"\n"}
                            Make sure you have both your Cat(s) and Rat(s) in the same wallet.{"\n"}{"\n"}
                          - Step 3.{"\n"}{"\n"}
                          To "enhance" your Quantum Cat(s), to make him win the competiton, you now have two options for the Quantum Rat(s):{"\n"}{"\n"}
                          Option 1: Sacrifice to Satoshi Nakamoto's Wallet{"\n"}{"\n"}
                          Transfer the Quantum Rat(s) directly to Satoshi Nakamoto's wallet at the address:{"\n"}{"\n"}
                          1A1zP1eP5QGefi2DMPTfTL5SLmv7DivfNa (
                                    <Text style={{color: 'grey', cursor:'pointer',  fontSize:  (isDesktopLandscape) ? 20 : 12}}
                                      onPress={() => Linking.openURL('https://ordiscan.com/address/1A1zP1eP5QGefi2DMPTfTL5SLmv7DivfNa')}> Ordiscan  
                                    </Text>
                                  ) {"\n"}{"\n"}
                          Rats sacrificed in this manner will receive the 'dead' status.{"\n"}{"\n"}
                          Option 2: Drain Life Energy{"\n"}{"\n"}

                          bc1pj7j6wq252rqnf8ntp5ucdm4h2upc3swnuk8luh7tkrkpkzvmnqnq7awpkz (
                                    <Text style={{color: 'grey', cursor:'pointer',  fontSize:  (isDesktopLandscape) ? 20 : 12}}
                                      onPress={() => Linking.openURL('https://ordiscan.com/address/bc1pj7j6wq252rqnf8ntp5ucdm4h2upc3swnuk8luh7tkrkpkzvmnqnq7awpkz')}> Ordiscan  
                                    </Text>
                                  ) {"\n"}{"\n"}

                          Rats that have their life energy drained in this way will be given the 'drained' status. A 'drained' rat still has a chance to be resurrected.{"\n"}{"\n"}

                          Scoring:{"\n"}{"\n"}

                          Rats with the 'dead' status contribute 1 points towards your Quantum Cat's enhancement.{"\n"}
                          Rats with the 'drained' status contribute 1 point towards your Quantum Cat's enhancement.{"\n"}{"\n"}

                          Completion of these steps concludes the enhancement process. Upon executing the outlined procedures, your Quantum Cat(s), residing in your wallet, will assimilate the magical essence of the Quantum Rat(s), thereby ascending in power. Progress can be monitored on the leaderboard.{"\n"}{"\n"}
                      
                      </Text>
                      
                      <TouchableWithoutFeedback
                        style={[stylesFaq.button, stylesFaq.buttonClose]}
                        onPress={() => toggleFaqModal()}>
                        <View style={[stylesFaq.button, stylesFaq.buttonClose,{cursor:'pointer', marginTop:10, width:150, height: (isDesktopLandscape) ? 60 : 30, alignItems:'center', justifyContent:'center', backgroundColor:'#0000', borderWidth:1.5, borderColor: "#fff"}]}>
                          <Text style={[stylesFaq.buttonTextStyle, {fontSize:  (isDesktopLandscape) ? 20 : 12}]}>Close Instructions</Text>
                        </View>
                      </TouchableWithoutFeedback>
                    </View>
                  </View>
                </Modal> 

      )

    }

    function downHandler({key}) {
      if (key === 'x') {
        setXHeld(true);
      }
      if (key === 'Control') {
        setControlHeld(true);
      }
      if (key === 'y') {
        setYHeld(true);
      }
    }
  
    function upHandler({key}) {
      if (key === 'x') {
        setXHeld(false);
      }
      if (key === 'Control') {
        setControlHeld(false);
      }
      if (key === 'y') {
        setYHeld(false);
      }
      
    }

   


 /* ------------------------------------------------------ */

 const imagestyles = StyleSheet.create({
  basicPosition: {
      top:10, left:10, width: (ScreenHeight * 0.17), height: (ScreenHeight * 0.15)-30
  },
  shadowProp: {
      //Ios
      shadowColor: "#000",
      shadowOffset: {
          width: 0,
          height: 2,
      },
      shadowOpacity: 0.23,
      shadowRadius: 2.62,
      //Android
      elevation: 4,
      
      borderRadius: 10, 
      //overflow: 'hidden'
  },
}); 

 const renderRatImages = () =>  {

  if (itemData && itemData.length > 0) {
     return ( 
        <View style={{paddingLeft: 5, height:'25%', width: '100%', backgroundColor: "#0000" }}>
          <Text style={[stylesItem.notSoBigTextStyleWhite, {fontSize:  (isDesktopLandscape) ? 15 : 10, paddingBottom:5, textAlign:'left', textAlignVertical:'center'}]}>{(collectionType==2) ? (itemData[0].qrat_burned_type=="0" ? "Drained" : "Killed") + " by" : "Drained or killed rats"}</Text>
          <ScrollView horizontal={true} style={{height:"100%", width: '100%', backgroundColor: "#0000" }}>
              {itemData.map((rat, index) => {
                return ( 
                  //styles.shadowProp
                      <View style={{paddingTop: 0, paddingBottom: 0, paddingLeft: 0, width: (ScreenHeight * 0.15)-10, height: (ScreenHeight * 0.15)-10, backgroundColor: "#0000"}}>
                          <Image
                              source={(collectionType!=2 ? require('./img/imagesQRats/' + rat.qrat_image) : require('./img/imagesQCats/' + rat.qcat_image))} 
                              style={[imagestyles.basicPosition, imagestyles.shadowProp, {height:100, width:100}]} 
                          />
                          <Text style={[stylesItem.notSoBigTextStyleWhite, {paddingTop:11, fontSize:  (isDesktopLandscape) ? 11 : 8, paddingBottom:1, textAlign:'center', textAlignVertical:'center'}]}>{(collectionType!=2) ? rat.qrat_name + (rat.qrat_burned_type=="0" ? " (Drained)" : " (Killed)") : rat.qcat_name}</Text>
                      </View>
                  )
              })}
          </ScrollView>
      </View> )
  }
  
  return "";
   
};

 

    //<Text style={[stylesItem.textStyle, {fontSize: '1rem', textAlign:'left', textAlignVertical:'center', paddingBottom:5}]}>Twitter Id (optional)</Text>

    const renderOrdinalItem = ({ item, index }) => (
      
      <View key={index} style={{height:80, flexDirection:'column', alignItems:'center', justifyContent:'center'}}>
          <View style={{height:35, flexDirection:'row', alignItems:'center', justifyContent:'flex-start'}}>
            <View style={{paddingLeft: 0, paddingTop: 0, justifyContent:'center', backgroundColor: "#FFFFFF", width: '8%'}}>
                  <TextInput
                      style={{fontFamily:'ShareTechMono', height: 30, fontSize:(isDesktopLandscape) ? 20 : 12, textAlign:'center',  backgroundColor: (item.item_verified>0 ? 'white' : 'darkgrey'), width: "99%",fontWeight:'bold', color: 'black', borderWidth: 3,  marginBottom: 0 }}
                      value={index + 1}
                      editable = {false}
                      placeholder={index + 1}/>
              </View>    
              <View style={{paddingLeft: 0, paddingTop: 0, justifyContent:'center', backgroundColor: "#FFFFFF", width: '15%'}}>
                  <TextInput
                      style={{fontFamily:'ShareTechMono', height: 30, fontSize:(isDesktopLandscape) ? 20 : 12, textAlign:'center', backgroundColor: (item.item_verified>0 ? 'white' : 'darkgrey'), width: "99%",fontWeight:'bold', color: 'black', borderWidth: 3,  marginBottom: 0 }}
                      value={'#' + item.item_rank}
                      editable = {false}
                      placeholder={'#' + item.item_rank}/>
              </View>
              <View style={{paddingLeft: 0, paddingTop: 0, justifyContent:'center', backgroundColor: "#FFFFFF", width: '57%'}}>
                  <TextInput
                      style={{fontFamily:'ShareTechMono', textAlign:'center', paddingLeft:3, height: 30, fontSize:(isDesktopLandscape) ? 20 : 10,  backgroundColor: (item.item_verified>0 ? 'white' : 'darkgrey'), width: "99%",fontWeight:'bold', color: 'black', borderWidth: 3,  marginBottom: 0 }}
                      value={item.item_timestamp + ' ' + item.item_timestamp_timezone}
                      editable = {false}
                      placeholder={item.item_timestamp + ' ' + item.item_timestamp_timezone}/>
              </View>    
              
            
              <View style={{paddingLeft: 0, paddingTop: 0, justifyContent:'center', backgroundColor: (item.item_verified>0 ? 'white' : 'darkgrey'), width: '20%'}}>
                  <View style={{cursor:'pointer', width: '99%', height: 30, alignItems:'center', justifyContent:'center', backgroundColor: (item.item_verified>0 ? 'white' : 'darkgrey'), borderWidth: 3, borderRadius:5}}>
                    <Text style={{fontFamily:'ShareTechMono', color: 'grey', cursor:'pointer',  fontSize: (isDesktopLandscape) ? 20 : 12}}
                            onPress={() => Linking.openURL('https://ordinals.com/inscription/' + item.item_ordinal)}> {(isDesktopLandscape) ? "Ordinals⇨ " : "Ord⇨ "}
                    </Text>
                  </View>
            </View>
        </View >
        <View style={{paddingBottom: 10, width: '100%', height:40, flexDirection:'row', alignItems:'center', justifyContent:'flex-start'}}>
            {(isDesktopLandscape) ?
            <View style={{paddingLeft: 0, paddingTop: 0, justifyContent:'center', backgroundColor: "#0000", width: '8%'}}>
                  <TextInput
                      style={{fontFamily:'ShareTechMono', height: 30, fontSize:(isDesktopLandscape) ? 20 : 12, textAlign:'center',  backgroundColor: '#0000', width: "99%",fontWeight:'bold', color: 'black', borderWidth: 0,  marginBottom: 0 }}
                      value={'Addr'}
                      editable = {false}
                      placeholder={'Addr'}/>
              </View> 
              : ""}   
              <View style={{paddingTop: 0, justifyContent:'center', backgroundColor: "#FFFFFF", width: (isDesktopLandscape) ? '92%' : "100%"}}>
                  <TextInput
                      style={{fontFamily:'ShareTechMono', height: 30, fontSize:(isDesktopLandscape) ? 20 : 9, textAlign:'center', backgroundColor: (item.item_verified>0 ? 'white' : 'darkgrey'), width: "100%",fontWeight:'bold', color: 'black', borderWidth: 3,  marginBottom: 0 }}
                      value={item.item_address}
                      editable = {false}
                      placeholder={item.item_address}/>
              </View>
        </View >
      </View>
    );

    function cancelDataViewPanel() {
      setDataViewPanelVisible(false);
      setSelectedItemForDataView('');
    }

    function renderDataViewPanel () {

      return (

        <Modal visible={isDataViewPanelVisible}
                  animationType="slide"
                  transparent={true}>
                  <View style={stylesItem.centeredView}>
                    <View style={[stylesItem.modalView, {padding: (isDesktopLandscape) ? 35 : 15, flexDirection:'column', width: (isDesktopLandscape) ? 1000 : "100%"}]}>
                      
                      <View style={{width:  (isDesktopLandscape) ? 1000 : '100%', height: 500, backgroundColor: "#0F0A22", width: '100%', flexDirection: "row"}}>

                          {/* <View style={{height: 500, backgroundColor: "#0000", width: '8%' , alignItems: 'center'}}>
                            <TouchableWithoutFeedback
                                style={[stylesItem.button, stylesItem.buttonClose]}
                                onPress={() => cancelDataViewPanel()}>
                                <View style={{cursor:'pointer', height: 50, backgroundColor: "#0000", width: '100%', borderWidth:3, borderColor:"white", alignItems:'center', justifyContent:'center'}}>
                                    <Text style={[stylesItem.bigTextStyleWhite, {fontSize:  (isDesktopLandscape) ? 20 : 15}]}>X</Text>
                                </View>
                            </TouchableWithoutFeedback>
                          </View> */}
                          
                          {/* <View style={{paddingLeft: 2, height: 500, backgroundColor: "#0000", width: (isDesktopLandscape) ? '14%' : '10%' , alignItems: 'center', justifyContent:'flex-start'}}>
                              <View style={{height: 50, backgroundColor: "#FFFFFF", width: '100%', alignItems:'center', justifyContent:'center'}}>
                                <Text style={[stylesItem.bigTextStyle, {fontSize:  (isDesktopLandscape) ? 20 : 12}]}>{selectedItemForDataView.btc_ordinal}</Text>
                              </View>
                          </View> */}
                          
                          <View style={{borderWidth:2, paddingLeft: 2, height: 600, backgroundColor: "blue", width: (isDesktopLandscape) ? '78%' : '82%'}}>
                              {/* DataView */}
                              <View style={{backgroundColor: "#0000", width: '100%', flexDirection:"column", flex:1 }}>
                                  <View style={{paddingLeft: 5, paddingTop: 5, backgroundColor: "#0000", width: '100%'}}>
                                    <Text style={[stylesItem.bigTextStyleWhite, {fontSize:  (isDesktopLandscape) ? 20 : 15, paddingBottom:5, textAlign:'left', textAlignVertical:'center'}]}>{(itemData &&itemData[0]) ? (collectionType!=2) ? itemData[0].qcat_name : itemData[0].qrat_name : ""}</Text>
                                  </View>
                                  

                                  <View style={{width:'98%', height: 240, paddingLeft: 5, paddingTop: 10,justifyContent: 'center', alignItems: 'center'}}>
                                    {(itemData && itemData[0] && itemData[0].type) ?
                                      <Image source={(collectionType!=2 ? require('./img/imagesQCats/' + itemData[0].qcat_image) : require('./img/imagesQRats/' + itemData[0].qrat_image))} style={{height:240, width:240}} ></Image>
                                     :
                                   ""}
                                    
                                  </View>
                                   
                              </View>
                              
                              {renderRatImages()}

                              <View style={{height:70, backgroundColor: "blue", width: '100%', flexDirection:"row"}}>
                                  <View style={{paddingLeft: 5, height:'100%', backgroundColor: "#0000", width: '50%', alignItems:'flex-start' }}>
                                    <TouchableWithoutFeedback
                                      style={[stylesItem.button]}
                                      onPress={() => cancelDataViewPanel()}>
                                      <View style={[stylesItem.button,{color:'black', cursor:'pointer', width:60, height:60, alignItems:'center', justifyContent:'flex-end', backgroundColor:'#FFF', borderWidth:2, borderColor: "#252525"}]}>
                                        <Text style={[stylesItem.bigTextStyle,{}]}>ok</Text>
                                      </View>
                                    </TouchableWithoutFeedback>
                                  </View>
                                  {/* <View style={{paddingRight: 20, height:'100%', backgroundColor: "#FFFFFF", width: '50%', alignItems:'flex-end' }}>
                                    <TouchableWithoutFeedback
                                      style={[stylesItem.button]}
                                      onPress={() => saveTransaction()}>
                                      <View style={[stylesItem.button,{color:'black', cursor:'pointer', width:60, height:60, alignItems:'center', justifyContent:'flex-end', backgroundColor:'#FFF', borderWidth:2, borderColor: "#252525"}]}>
                                        <Text style={[stylesItem.bigTextStyle,{}]}>go</Text>
                                      </View>
                                    </TouchableWithoutFeedback>
                                  </View> */}
                              </View>
                          </View>
                        
                        

                      </View>

                     

                    </View>
                  </View>
                </Modal> 

      )

    }


    function setFilteredItemSet(text) {
        setOffset(1);
        setFilteredItem(text);
    }

    const renderSearch = () => {
      return <View style={{width:(isDesktopLandscape) ? 200 : '100%',backgroundColor: "#0F0A22", height: (isDesktopLandscape) ? 60 : 50, flexDirection: "column", alignItems: 'center', justifyContent: 'center'}}>
                <View style={{width: (isDesktopLandscape) ? '100%' : '50%',backgroundColor: "#0000", height: (isDesktopLandscape) ? 30 : 25, flexDirection: "row", alignItems: 'center', justifyContent: 'center'}}>
                    <TextInput  placeholder="search" value={filteredItem} onChangeText={setFilteredItemSet} style={{fontFamily:'ShareTechMono', textAlign:'center', flex:1, height:(isDesktopLandscape) ? 30 : 25, backgroundColor: 'white', borderWidth: 2, color:'#000', borderColor: "#FFF", borderRadius: 20}} />
                    <TouchableWithoutFeedback onPress={() => {
                              setOffset(1);
                              setFilteredItem('');
                            }}>
                            <View style={[stylesFaq.smallbutton,{cursor:'pointer', width:55, height:(isDesktopLandscape) ? 30 : 25, alignItems:'center', justifyContent:'center', backgroundColor:'#0F0A22', borderWidth:2, borderColor: "#fff", borderRadius:20}]}>
                              <Text style={{fontFamily:'ShareTechMono', fontSize: (isDesktopLandscape) ? 15 : 12, color: "#FFFFFF" }}>Clear</Text>
                          </View>
                    </TouchableWithoutFeedback>
                </View>
                <View style={{paddingTop: 10, width:'100%',backgroundColor: "#0000", height: (isDesktopLandscape) ? 30 : 15}}>
                      <Text style={[stylesItem.textStyleWhite,{fontWeight:'normal', fontSize: 12}]}>(supports names, ids, numbers)</Text>
                </View>
            </View>
    }

    const changeNumColumns = (newNumColumns) => {
      setNumColumns(newNumColumns);
      setListKey(newNumColumns === 1 ? 'one-column' : 'multi-column');
    };

    // width={ScreenWidth} height={ScreenHeight}
  return (
    <View style={styles.container}>
       {renderFaq()}
     {/*  <ImageBackground source={require('./img/btcdgts-parallax.png')}  resizeMode='cover' style={{width: "100%" , justifyContent: 'center'}}> */}
      
            
          {/* <View style={{paddingLeft:(isDesktopLandscape) ? 175 : 0, height:60,backgroundColor: "#252525", width: '100%', flexDirection: "row" , alignItems: 'center'}}> */}
          <View style={{height:60,backgroundColor: "#0F0A22", width: '100%' , alignItems: 'center'}}>
            <View style={{height:60,backgroundColor: "#0000", width: '90%', flexDirection: "row" , alignItems: 'center'}}>
                <View style={{zIndex: '99 !important',minWidth: 40, width:'15%',backgroundColor: "#0000", height: '100%', alignItems: 'center', justifyContent: 'center'}}>
                {/* <View style={{zIndex: '99 !important', paddingLeft:(isDesktopLandscape) ? 100 : 0,minWidth:170, width:'10%',backgroundColor: "#252525", height: '100%', alignItems: 'center', justifyContent: 'center'}}> */}
                    <Image source={require('./img/QPETS-logo.png')} style={{resizeMode:'stretch', height:(isDesktopLandscape) ? 65 : 36, width:(isDesktopLandscape) ? 280 : 112}} ></Image> 
                </View>
                 <View style={{paddingLeft: 15, width:'45%',backgroundColor: "#0000", height: '100%', alignItems: 'center' , justifyContent: 'center'}}>
             {/*       <TouchableWithoutFeedback onPress={() => {
                          ////console.log('x:' + xHeld, 'y:' + yHeld, 'control:' + controlHeld)
                                if (xHeld && controlHeld && yHeld) {
                                 
                                } 
                            }}>
                    
                          <Text style={{fontFamily:'ShareTechMono', fontSize: (isDesktopLandscape) ? 40 : 20, color:  '#FFFFFF' }}>CATS VS RATS</Text>
                    </TouchableWithoutFeedback>*/}
                </View> 
                <View style={{width:'40%',backgroundColor: "#0000", height: '100%', flexDirection: "row", alignItems: 'center', justifyContent:'flex-end'}}>
                  
               
    
                  {/* <TouchableWithoutFeedback onPress={() => {
                              toggleAboutModal();
                          }}>
                          <Text style={[styles.faq,{fontSize: (isDesktopLandscape) ? 20 : 12}]}>About  </Text>
                    </TouchableWithoutFeedback> */}
    
                     {/*  {renderAbout()} */}
    
                   <TouchableWithoutFeedback onPress={() => {
                              toggleFaqModal();
                          }}>
                          <Text style={[styles.faq,{paddingLeft:3,fontSize: (isDesktopLandscape) ? 20 : 12}]}>INSTRUCTIONS  </Text>
                    </TouchableWithoutFeedback>
    
                      
                      
                      <TouchableWithoutFeedback onPress={() => {
                          Linking.openURL('https://magiceden.io/ordinals/marketplace/quantum_cats');
                      }}>
                        <View style={{cursor:'pointer', paddingTop:(isDesktopLandscape) ? 7 : 4, paddingRight:(isDesktopLandscape) ? 10 : 0, paddingLeft:(isDesktopLandscape) ? 10 : 5, height:(isDesktopLandscape) ? 40 : 25,backgroundColor: "#0000", width: (isDesktopLandscape) ? 40 : 25 , alignItems: 'center', justifyContent:'center'}}>
                        <img src={meden_icon}   height={(isDesktopLandscape) ? 40 : 25} width={(isDesktopLandscape) ? 40 : 25} />
                          </View>
                      </TouchableWithoutFeedback>

                      <TouchableWithoutFeedback onPress={() => {
                          Linking.openURL('https://magiceden.io/ordinals/marketplace/quantumrats');
                      }}>
                         <View style={{cursor:'pointer', paddingTop:(isDesktopLandscape) ? 7 : 4, paddingRight:(isDesktopLandscape) ? 10 : 0, paddingLeft:(isDesktopLandscape) ? 10 : 5, height:(isDesktopLandscape) ? 40 : 25,backgroundColor: "#0000", width: (isDesktopLandscape) ? 40 : 25 , alignItems: 'center', justifyContent:'center'}}>
                        <img src={meden_icon}   height={(isDesktopLandscape) ? 40 : 25} width={(isDesktopLandscape) ? 40 : 25} />
                          </View>
                      </TouchableWithoutFeedback>

                     {/*  <TouchableWithoutFeedback onPress={() => {
                          Linking.openURL('https://x.com/QuantumCatsXYZ');
                      }}>
                        <View style={{cursor:'pointer', paddingLeft:(isDesktopLandscape) ? 10 : 2, height:(isDesktopLandscape) ? 30 : 20,backgroundColor: "#0000", width: (isDesktopLandscape) ? 30 : 20 , alignItems: 'center'}}>
                        <img src={twitter_icon_svg}   height={(isDesktopLandscape) ? 30 : 20} width={(isDesktopLandscape) ? 30 : 20} />
                          </View>
                      </TouchableWithoutFeedback> */}
                      
                      
                      <Text style={styles.title}>  </Text>
                      {/*}<Image source={require('./img/twitter-icon.png')} style={{height:30, width:30}} ></Image>  */}
                      
                   {/*    <TouchableWithoutFeedback onPress={() => {
                          Linking.openURL('https://discord.gg/taprootwizards');
                      }}>
                        <View style={{cursor:'pointer', height:(isDesktopLandscape) ? 30 : 20,backgroundColor: "#0000", width: (isDesktopLandscape) ? 30 : 20 , alignItems: 'center'}}>
                              <img src={discord_icon_svg} height={(isDesktopLandscape) ? 30 : 20} width={(isDesktopLandscape) ? 30 : 20} />
                          </View>
                      </TouchableWithoutFeedback> */}
                      
                        
                  </View>
              
            </View>
          </View>

          <View style={{height: ScreenHeight - 170}} >
              <ScrollView style={{backgroundColor: "#0000", width: '100%'}}>


                  {/* <View style={{height:0,backgroundColor: "#0000", width: '100%' , alignItems: 'center'}}>
                      <img src={lobtcdgtsgo_svg}   />
                  
                  </View> */}
                  
                {/*  <View style={{height:30,backgroundColor: "#252525", width: '100%' , alignItems: 'center'}}>
                    <Text style={styles.title}>The first digits to crawl into the bitcoin network...</Text>
                    </View>
        */}
                  
                  {/* <View style={{height: (isDesktopLandscape) ? 300 : 150,backgroundColor: "#0000", width: '100%' , alignItems: 'center'}}>

                  </View> */}

                
                    {renderDataViewPanel()}
                    
               {/*    <View style={{paddingBottom:0, height:110, width:'70%', backgroundColor: "#252525", width: '100%', alignItems: 'center', justifyContent:'center'}}>
                      <Text style={[styles.abouttext,{fontFamily:'ShareTechMono', fontSize: (isDesktopLandscape) ? 20 : 10 , color:  'red' }]}>Beware! There is an unverified copy colection of Bitcoin Digits on OKX (and also an old unused one) ! (Please do not buy until the case is cleared, we are in contact with OKX) scammers even copied our logo and website design to their discord.</Text>
                  </View> */}
                 {/*  <View style={{paddingBottom:20,height:(isDesktopLandscape) ? 170 : 150, width:'70%', backgroundColor: "#0000", width: '100%', alignItems: 'center', justifyContent:'center'}}>
                      <Text style={[styles.abouttext, {fontSize: (isDesktopLandscape) ? 20 : 10}]}>WELCOME TO THE BITCOIN DIGITS
            The Bitcoin Digits inscription(NFT) collection containing the very first and earliest registered digits in Ordinals. The collection is positioned to become one of the first projects to make a significant impact on the market. This means that some digits of the collection will have a sub 1k inscription, which is really f….ng low. This speaks to the level of engagement and enthusiasm within the community, as well as the quality and uniqueness of the project being offered.</Text>

                  </View> */}
                  
                  <View style={{marginTop:30, height:(isDesktopLandscape) ? 70 : 40,backgroundColor: "#0000", width: '100%', flexDirection: "row" , alignItems: 'center', justifyContent:'space-evenly'}}>



                  <TouchableWithoutFeedback onPress={() => {
                                setOffset(1);
                                setFilteredItem('');
                                setCollectionType(3);
                                changeNumColumns(1);
                                setCollectionIsPoker(0);
                            }}>
                            <View style={[stylesFaq.button, {cursor:'pointer', width:(isDesktopLandscape) ? 200 : 100, height:(isDesktopLandscape) ? 66 : 33, alignItems:'center', justifyContent:'center', backgroundColor: '#0000', borderColor: (collectionType == 3 ? '#FFFFFF' : '#696969')}]}>
                            {/* <View onMouseEnter={MouseEnterBtn} onMouseLeave={onMouseLeaveBtn} style={[stylesFaq.button, stylesFaq.buttonClose,{cursor:'pointer', width:150, height:60, alignItems:'center', justifyContent:'center', backgroundColor:'#000', borderWidth:1.5, borderColor: "#fff"}]}> */}
                            
                              <Text style={{fontFamily:'ShareTechMono', fontSize: (isDesktopLandscape) ? 15 : 15, color:  (collectionType == 3 ? '#FFFFFF' : '#696969') }}>{(isDesktopLandscape) ? 'Feast Leaderboard' : 'Feast Leaderboard'}</Text>
                          </View>
                      </TouchableWithoutFeedback>
                      
                      <TouchableWithoutFeedback onPress={() => {
                                setOffset(1);
                                setFilteredItem('');
                                setCollectionType(1);
                                changeNumColumns((isDesktopLandscape) ? 10 : 5);
                                setCollectionIsPoker(0);
                            }}>
                            <View style={[stylesFaq.button,{cursor:'pointer', width:(isDesktopLandscape) ? 200 : 100, height:(isDesktopLandscape) ? 66 : 33, alignItems:'center', justifyContent:'center', backgroundColor: '#0000', borderColor: (collectionType == 1 ? '#FFFFFF' : '#696969')}]}>
                              
                              <Text style={{fontFamily:'ShareTechMono', fontSize: (isDesktopLandscape) ? 15 : 15, color:  (collectionType == 1 ? '#FFFFFF' : '#696969') }}>{(isDesktopLandscape) ? 'QCats Collection' : 'QCats Collection'}</Text>
                          </View>
                      </TouchableWithoutFeedback>

                      <TouchableWithoutFeedback onPress={() => {
                                setOffset(1);
                                setFilteredItem('');
                                setCollectionType(2);
                                changeNumColumns((isDesktopLandscape) ? 10 : 5);
                                setCollectionIsPoker(0);
                            }}>
                            <View style={[stylesFaq.button, {cursor:'pointer', width:(isDesktopLandscape) ? 200 : 100, height:(isDesktopLandscape) ? 66 : 33, alignItems:'center', justifyContent:'center', backgroundColor: '#0000', borderColor: (collectionType == 2 ? '#FFFFFF' : '#696969')}]}>
                            {/* <View onMouseEnter={MouseEnterBtn} onMouseLeave={onMouseLeaveBtn} style={[stylesFaq.button, stylesFaq.buttonClose,{cursor:'pointer', width:150, height:60, alignItems:'center', justifyContent:'center', backgroundColor:'#000', borderWidth:1.5, borderColor: "#fff"}]}> */}
                            
                              <Text style={{fontFamily:'ShareTechMono', fontSize: (isDesktopLandscape) ? 15 : 15, color:  (collectionType == 2 ? '#FFFFFF' : '#696969') }}>{(isDesktopLandscape) ? 'QRats Collection' : 'QRats Collection'}</Text>
                          </View>
                      </TouchableWithoutFeedback>

                      

                     

{/*                       <TouchableWithoutFeedback onPress={() => {
                                setOffset(1);
                                setFilteredItem('');
                                setCollectionType(6);
                            }}>
                            <View style={[stylesFaq.button,{cursor:'pointer', width:(isDesktopLandscape) ? 140 : 50, height:(isDesktopLandscape) ? 60 : 30, alignItems:'center', justifyContent:'center', backgroundColor: '#252525', borderColor: (collectionType == 6 ? '#FFFFFF' : '#696969')}]}>
                              <Text style={{fontFamily:'ShareTechMono', fontSize: (isDesktopLandscape) ? 15 : 12, color: (collectionType == 6 ? '#FFFFFF' : '#696969') }}>{(isDesktopLandscape) ? 'PRIME BTC club' : 'PRIME'}</Text>
                          </View>
                      </TouchableWithoutFeedback>

                      <TouchableWithoutFeedback onPress={() => {
                                setOffset(1);
                                setFilteredItem('');
                                setCollectionType(7);
                            }}>
                            <View style={[stylesFaq.button,{cursor:'pointer', width:(isDesktopLandscape) ? 140 : 50, height:(isDesktopLandscape) ? 60 : 30, alignItems:'center', justifyContent:'center', backgroundColor: '#252525', borderColor: (collectionType == 7 ? '#FFFFFF' : '#696969')}]}>
                              <Text style={{fontFamily:'ShareTechMono', fontSize: (isDesktopLandscape) ? 15 : 12, color: (collectionType == 7 ? '#FFFFFF' : '#696969') }}>{(isDesktopLandscape) ? 'FIBO BTC club' : 'FIBO'}</Text>
                          </View>
                      </TouchableWithoutFeedback> */}

                      {(isDesktopLandscape) ?
                        renderSearch()
                      :""}
                  </View>


                  

                  {(!isDesktopLandscape) ?
                        renderSearch()
                      :""}
                  
                  <View style={{paddingTop:(isDesktopLandscape) ? 20 : 10, backgroundColor: "#0000", width: '100%', alignItems: 'center', justifyContent:'space-evenly'}}>
                      <View style={[styles.app, {paddingBottom: 10, width: (isDesktopLandscape) ? 800 : "80%"}]}>
                        {/* <Text style={[styles.title, {fontSize: (isDesktopLandscape) ? 20 : 12}]}>{filteredItem!='' ? 'Search result (' + filteredItem +  ')' : collectionType + 'D BTC club'}</Text> */}
                        {(collectionType!=3) ? 
                        <View style={{width:'100%', height:(isDesktopLandscape) ? 80 : 50,backgroundColor: "#0000", flexDirection: "row" , alignItems: 'center', justifyContent:'center'}}>
                            <View style={{width: (isDesktopLandscape) ? '45%' : '35%',backgroundColor: "#0000", height: '100%', flexDirection: "row" , alignItems: 'center', justifyContent: 'center'}}>
                                    {(isDesktopLandscape) ? <Text style={{ fontFamily:'ShareTechMono', fontSize: 14, color: "#FFF" }}>Filter by: </Text> :""}
                                    <TouchableWithoutFeedback onPress={() => {
                                            setOffset(1);
                                            setFilterType('Registered');
                                        }}>
                                        <View style={[stylesFaq.smallbutton,{cursor:'pointer', width: (isDesktopLandscape) ? 160 : 40, height:(isDesktopLandscape) ? 40 : 30, alignItems:'center', justifyContent:'center', backgroundColor: '#0000', borderWidth:1.5, borderColor: (filterType == 'Registered'? '#FFFFFF' : '#696969')}]}>
                                        {/* <View onMouseEnter={MouseEnterBtn} onMouseLeave={onMouseLeaveBtn} style={{cursor:'pointer', width:80, height:40, alignItems:'center', justifyContent:'center', backgroundColor:'#000', borderWidth:1.5, borderColor: "#fff"}}> */}
                                          <Text style={{fontFamily:'ShareTechMono', fontSize: (isDesktopLandscape) ? 15 : 12, color: (filterType == 'Registered'? '#FFFFFF' : '#696969') }}>{(isDesktopLandscape) ? 'Drained or killed' : 'D or K'}</Text>
                                      </View>
                                  </TouchableWithoutFeedback>
                                  <TouchableWithoutFeedback onPress={() => {
                                            setOffset(1);
                                          // setSubType('All');
                                            setFilterType('Empty');
                                        }}>
                                        <View style={[stylesFaq.smallbutton,{cursor:'pointer', width:(isDesktopLandscape) ? 80 : 30, height:(isDesktopLandscape) ? 40 : 30, alignItems:'center', justifyContent:'center', backgroundColor: '#0000', borderWidth:1.5, borderColor: (filterType == 'Empty'? '#FFFFFF' : '#696969')}]}>
                                        {/* <View onMouseEnter={MouseEnterBtn} onMouseLeave={onMouseLeaveBtn} style={{cursor:'pointer', width:80, height:40, alignItems:'center', justifyContent:'center', backgroundColor:'#000', borderWidth:1.5, borderColor: "#fff"}}> */}
                                          <Text style={{fontFamily:'ShareTechMono', fontSize: (isDesktopLandscape) ? 15 : 12, color: (filterType == 'Empty'? '#FFFFFF' : '#696969') }}>{(isDesktopLandscape) ? 'Alive' : 'Alive'}</Text>
                                      </View>
                                  </TouchableWithoutFeedback>
                                  <TouchableWithoutFeedback onPress={() => {
                                            setOffset(1);
                                            setFilterType('All');
                                        }}>
                                          <View style={[stylesFaq.smallbutton,{cursor:'pointer', width:(isDesktopLandscape) ? 80 : 30, height:(isDesktopLandscape) ? 40 : 30, alignItems:'center', justifyContent:'center', backgroundColor: '#0000', borderWidth:1.5, borderColor: (filterType == 'All'? '#FFFFFF' : '#696969')}]}>
                                        {/* <View onMouseEnter={MouseEnterBtn} onMouseLeave={onMouseLeaveBtn} style={{cursor:'pointer', width:80, height:40, alignItems:'center', justifyContent:'center', backgroundColor:'#000', borderWidth:1.5, borderColor: "#fff"}}> */}
                                          <Text style={{fontFamily:'ShareTechMono', fontSize: (isDesktopLandscape) ? 15 : 12, color: (filterType == 'All'? '#FFFFFF' : '#696969') }}>All</Text>
                                      </View>
                                  </TouchableWithoutFeedback>
                            </View>
                            <View style={{width:'20%',backgroundColor: "#0000", height: '100%', flexDirection: "row" , alignItems: 'center', justifyContent: 'center'}}>
                                    {(isDesktopLandscape) ? <Text style={{fontFamily:'ShareTechMono', fontSize: 14, color: "#FFF" }}>Sort: </Text> : ""}
                                    <TouchableWithoutFeedback onPress={() => {
                                            setOffset(1);
                                            setSortType('Asc');
                                        }}>
                                        <View style={[stylesFaq.smallbutton,{cursor:'pointer', width:(isDesktopLandscape) ? 60 : 30, height:(isDesktopLandscape) ? 40 : 30, alignItems:'center', justifyContent:'center', backgroundColor: '#0000', borderWidth:1.5, borderColor: (sortType == 'Asc'? '#FFFFFF' : '#696969')}]}>
                                        {/* <View onMouseEnter={MouseEnterBtn} onMouseLeave={onMouseLeaveBtn} style={{cursor:'pointer', width:80, height:40, alignItems:'center', justifyContent:'center', backgroundColor:'#000', borderWidth:1.5, borderColor: "#fff"}}> */}
                                          <Text style={{fontFamily:'ShareTechMono', fontSize: (isDesktopLandscape) ? 15 : 12, color: (sortType == 'Asc'? '#FFFFFF' : '#696969') }}>Asc</Text>
                                      </View>
                                  </TouchableWithoutFeedback>
                                  <TouchableWithoutFeedback onPress={() => {
                                            setOffset(1);
                                            setSortType('Desc');
                                        }}>
                                        <View style={[stylesFaq.smallbutton,{cursor:'pointer', width:(isDesktopLandscape) ? 60 : 30, height:(isDesktopLandscape) ? 40 : 30, alignItems:'center', justifyContent:'center', backgroundColor: '#0000', borderWidth:1.5, borderColor: (sortType == 'Desc'? '#FFFFFF' : '#696969')}]}>             
                                          <Text style={{fontFamily:'ShareTechMono', fontSize: (isDesktopLandscape) ? 15 : 12, color: (sortType == 'Desc'? '#FFFFFF' : '#696969') }}>Desc</Text>
                                      </View>
                                  </TouchableWithoutFeedback>
                            </View>
                        </View>
                        :""}
                    {/*     {(collectionType==3) ?  */}
                          <FlatList
                            data={numbers}
                            numColumns={numColumns}
                            renderItem={(collectionType==3) ? renderItemLeaderboard : renderItem}
                            keyExtractor={(item) => item.id}
                            key={listKey}
                          />
                        {/*  :
                          <FlatList
                            data={numbers}
                            numColumns={(isDesktopLandscape) ? 10 : 5}
                            renderItem={renderItem}
                            keyExtractor={(item) => item.id}
                          />
                        }  */}
                      </View>
                  </View>
              </ScrollView>
          </View>

          <View style={{height:(isDesktopLandscape) ? 110 : 80,backgroundColor: "#0000", width: '100%', alignItems: 'center', justifyContent:'center'}}>
                  <TouchableWithoutFeedback onPress={() => {
                            loadCollections();
                        }}>
                        <View style={[stylesFaq.button,{cursor:'pointer', width:(isDesktopLandscape) ? 150 : 100, height:(isDesktopLandscape) ? 40 : 20, alignItems:'center', justifyContent:'center', backgroundColor: '#0F0A22', borderWidth:1.5, borderColor: (hasMoreItemToLoad? '#FFFFFF' : '#696969')}]}>
                          <Text style={{fontFamily:'ShareTechMono', fontSize: (isDesktopLandscape) ? 15 : 12, color: (hasMoreItemToLoad ? '#FFFFFF' : '#696969') }}>Load More</Text>
                      </View>
                  </TouchableWithoutFeedback>
                  <View style={{paddingTop:10, paddingBottom:0, height:60, width:'80%',backgroundColor: "#0000", flexDirection: "column" , alignItems: 'center', justifyContent: 'center'}}>
                    {(btcData) ?
                      <Text style={{fontFamily:'ShareTechMono', fontSize: (isDesktopLandscape) ? 12 : 8, color: (hasMoreItemToLoad ? '#FFFFFF' : '#FFFFFF') }}>next airdrop in block: {btcData.dropBlock} / in {btcData.blocksLeft} blocks - in {btcData.daysLeft} days - current block: {btcData.currentBlock} /</Text>
                    :""}
                    <Text style={{fontFamily:'ShareTechMono', fontSize: (isDesktopLandscape) ? 12 : 8, color: (hasMoreItemToLoad ? '#FFFFFF' : '#FFFFFF') }}>Quantumpets.xyz operates independently and is not officially associated with, endorsed by, or affiliated with the Taproot Wizards and Quantum Cats projects. References to these projects are for informational purposes only and do not imply partnership or collaboration. YET 🙂</Text>
                  </View>
                  
                  
          </View>
    
      {/* </ImageBackground>  */}

    </View>

   

  );
}


const stylesLeader = StyleSheet.create({
  item: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: 10,
    borderBottomWidth: 1,
    borderBottomColor: '#FFFFFF',
  },
  name: {
    // Style for the name text
  },
  score: {
    // Style for the score text
  },
});

const stylesImage = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  backgroundImage: {
    flex: 1,
    width: '100%', // vagy egy konkrét méret
    height: '100%', // vagy egy konkrét méret
    justifyContent: 'center',
    alignItems: 'center',
  },
  text: {
    color: 'white',
    fontSize: 20,
    fontWeight: 'bold',
  }
});

const stylesFaq = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 22,
    fontFamily:'ShareTechMono',
    
  },
  modalView: {
    margin: 20,
    backgroundColor: 'white',
    borderRadius: 10,
    padding: 35,
    alignItems: 'center',
    shadowColor: '#0000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    fontFamily:'ShareTechMono'
  },
  button: {
    borderRadius: 63,
    padding: 10,
    elevation: 2,
    borderWidth:2,
    fontFamily:'ShareTechMono'
  },
  smallbutton: {
    borderRadius: 0,
    padding: 10,
    elevation: 2,
    fontFamily:'ShareTechMono'
  },
  buttonOpen: {
    backgroundColor: '#F194FF',
  },
  buttonClose: {
    backgroundColor: '#2196F3',
  },
  buttonTextStyle: {
    color: 'white',
    fontWeight: 'bold',
    textAlign: 'center',
    fontFamily:'ShareTechMono'
  },
  textStyle: {
    color: 'black',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  modalText: {
    marginBottom: 15,
    textAlign: 'justify',
    fontSize: '1.3rem',
    fontFamily:'ShareTechMono'
  },
});

const stylesItem = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 22,
    backgroundColor:"#0000"
  },
  modalView: {
    width: 1000,
    height: 500,
    margin: 20,
    backgroundColor: '#0000',
    borderRadius: 0,
    padding: 35,
    alignItems: 'center',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    fontFamily:'ShareTechMono'
  },
  modalViewBig: {
    width: 1160,
    height: 700,
    margin: 20,
    backgroundColor: '#0000',
    borderRadius: 0,
    padding: 35,
    alignItems: 'center',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    fontFamily:'ShareTechMono',
    backgroundColor: 'white'
  },
  button: {
    borderRadius: 5,
    padding: 10,
    elevation: 2,
    fontFamily:'ShareTechMono'
  },
  smallbutton: {
    borderRadius: 0,
    padding: 10,
    elevation: 2,
    fontFamily:'ShareTechMono'
  },
  smallroundbutton: {
    borderRadius: 5,
    padding: 5,
    elevation: 2,
    fontFamily:'ShareTechMono'
  },
  buttonOpen: {
    backgroundColor: '#F194FF',
  },
  buttonClose: {
    backgroundColor: '#2196F3',
  },
  buttonTextStyle: {
    color: 'white',
    fontWeight: 'bold',
    textAlign: 'center',
    fontFamily:'ShareTechMono'
  },
  textStyleWhite: {
    color: 'white',
    fontWeight: 'bold',
    textAlign: 'center',
    fontFamily:'ShareTechMono'
  },
  textStyle: {
    color: 'black',
    fontWeight: 'bold',
    textAlign: 'center',
    fontFamily:'ShareTechMono'
  },
  middleTextStyle: {
    color: 'black',
    fontWeight: 'bold',
    textAlign: 'center',
    fontSize: '1rem',
    fontFamily:'ShareTechMono'
  },
  bigTextStyle: {
    color: 'black',
    fontWeight: 'bold',
    textAlign: 'center',
    textAlignVertical: 'center',
    fontSize: '2rem',
    fontFamily:'ShareTechMono'
  },
  bigTextStyleWhite: {
    color: 'white',
    fontWeight: 'bold',
    textAlign: 'center',
    fontSize: '2rem',
    fontFamily:'ShareTechMono'
  },
  notSoBigTextStyle: {
    color: 'black',
    fontWeight: 'bold',
    textAlign: 'center',
    textAlignVertical: 'center',
    fontSize: '1.4rem',
    fontFamily:'ShareTechMono'
  },
  notSoBigTextStyleWhite: {
    color: 'white',
    fontWeight: 'bold',
    textAlign: 'center',
    textAlignVertical: 'center',
    fontSize: '1.4rem',
    fontFamily:'ShareTechMono'
  },
  modalText: {
    marginBottom: 15,
    textAlign: 'justify',
    fontSize: '1.3rem',
    fontFamily:'ShareTechMono'
  }
});

const styles = {
  app: {
    flex: 10, // the number of columns you want to devide the screen into
    marginHorizontal: "auto",
    width: 800,
    backgroundColor: "#0F0A22",
    fontFamily:'ShareTechMono',
    FontFace:'ShareTechMono'
  },
  emptyitem: {
    flex: 1,
    maxWidth: "10%", // 100% devided by the number of rows you want
    alignItems: "center",
    
    // my visual styles; not important for the grid
    padding: 10,
    backgroundColor: "#0000", //"rgba(249, 180, 45, 0.25)",
    borderWidth: 1.5,
    borderColor: "#fff",
    color: '#fff',
    fontFamily:'ShareTechMono'
  },
  itemregistered: {
    flex: 1,
    maxWidth: "10%", // 100% devided by the number of rows you want
    alignItems: "center",
    cursor:'pointer',
    
    // my visual styles; not important for the grid
    padding: 10,
    backgroundColor: "#ffffff", //"rgba(249, 180, 45, 0.25)",
    borderWidth: 1.5,
    borderColor: "#fff",
    color: '#fff',
    fontFamily:'ShareTechMono'
  },
  itemverified: {
    flex: 1,
    maxWidth: "10%", // 100% devided by the number of rows you want
    alignItems: "center",
    cursor:'pointer',
    
    // my visual styles; not important for the grid
    padding: 10,
    backgroundColor: "white", //"rgba(249, 180, 45, 0.25)",
    borderWidth: 1.5,
    borderColor: "#0000",
    color: '#0000',
    fontFamily:'ShareTechMono'
  },
  emptyitem_small: {
    flex: 1,
    maxWidth: "20%", // 100% devided by the number of rows you want
    alignItems: "center",
    cursor:'pointer',
    
    // my visual styles; not important for the grid
    padding: 10,
    backgroundColor: "#0000", //"rgba(249, 180, 45, 0.25)",
    borderWidth: 1.5,
    borderColor: "#fff",
    color: '#fff',
    fontFamily:'ShareTechMono'
  },
  itemregistered_small: {
    flex: 1,
    maxWidth: "20%", // 100% devided by the number of rows you want
    alignItems: "center",
    cursor:'pointer',
    
    // my visual styles; not important for the grid
    padding: 10,
    backgroundColor: "darkgrey", //"rgba(249, 180, 45, 0.25)",
    borderWidth: 1.5,
    borderColor: "#fff",
    color: '#fff',
    fontFamily:'ShareTechMono'
  },
  itemverified_small: {
    flex: 1,
    maxWidth: "20%", // 100% devided by the number of rows you want
    alignItems: "center",
    cursor:'pointer',
    
    // my visual styles; not important for the grid
    padding: 10,
    backgroundColor: "white", //"rgba(249, 180, 45, 0.25)",
    borderWidth: 1.5,
    borderColor: "#0000",
    color: '#0000',
    fontFamily:'ShareTechMono'
  },
  container: {
    flex: 1,
    display: 'block',
    height: ScreenHeight,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#0F0A22',
    fontFamily:'ShareTechMono'
  
  },
  header: {
    padding: 20,
    width:'100%'
  },
  title: {
    fontWeight: 'bold',
    fontSize: '1.2rem',
    marginVertical: '0em',
    textAlign: 'center',
    color:'white',
    fontFamily:'ShareTechMono'
  },
  faq: {
    fontWeight: 'bold',
    fontSize: '1.2rem',
    marginVertical: '0em',
    textAlign: 'center',
    color:'white',
    cursor: 'pointer',
    fontFamily:'ShareTechMono'
  },
  abouttext: {
    width: '70%',
    fontWeight: 'normal',
    fontSize: '1.2rem',
    marginVertical: '0em',
    textAlign: 'justify',
    color:'white',
    fontFamily:'ShareTechMono'
  },
  text: {
    lineHeight: '1.5em',
    fontSize: '1.125rem',
    marginVertical: '1em',
    textAlign: 'center',
    fontFamily:'ShareTechMono'
  },
  link: {
    color: '#1B95E0'
  },
  code: {
    fontFamily:'ShareTechMono'
  },
  headerimage: {
    width: 200,
    height: 34,
    borderWidth: 1
  },
  loadMoreBtn: {
    padding: 10,
    backgroundColor: '#FFFFFF',
    borderRadius: 4,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    fontFamily:'ShareTechMono'
  }
};


export default App;